@font-face {
  font-family: "Noto Sans Kr Light";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans Light"), local("NotoSans-Light"),
    url(/NotoSans-Light.eot);
  src: url(/NotoSans-Light.eot?#iefix) format("embedded-opentype"),
    url(/NotoSans-Light.woff2) format("woff2"),
    url(/NotoSans-Light.woff) format("woff");
}

@font-face {
  font-family: "Noto Sans Kr Medium";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans Medium"), local("NotoSans-Medium"),
    url(/NotoSans-Lighter.eot);
  src: url(/NotoSans-Medium.eot?#iefix) format("embedded-opentype"),
    url(/NotoSans-Medium.woff2) format("woff2"),
    url(/NotoSans-Medium.woff) format("woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

#root {
  height: 100%;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-2 {
  margin-right: 8px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-1 {
  margin-bottom: 4px !important;
}

.m-b-2 {
  margin-bottom: 8px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.width-50 {
  width: 50%;
}

.maxWidth-80 {
  max-width: 80px;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-sb {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pointer {
  color: blue;
  cursor: pointer;
}

.title {
  font-size: 20px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}

.ant-table-thead>tr>th {
  /* text-align: center; */
  background-color: #cee2f1;
  /* border: 1px solid #c2c2c0;
  font-weight: 530; */
}

.support-column {
  word-break: break-all;
}

.support-column-title {
  max-width: 150px;
  word-break: break-all;
}

.text-center {
  text-align: center !important;
}

/* notice */
.detailModal-wrapper {
  /* border: 1px solid #696968; */
  border-radius: 0px;
  padding: 20px;
}

.detailRegistryModal-wrapper {
  border: 1px solid #696968;
  border-radius: 0px;
  padding: 20px;
}

.grid-title {
  border: 1px solid #cfcfcf;
  background-color: #cee2f1;
  text-align: center;
  height: 30px;
}

.grid-content {
  border: 1px solid #cfcfcf;
  padding-left: 10px;
}

.grid-content-bottom {
  border-bottom: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  padding-left: 10px;
}

.grid-content-subText> {
  min-height: 800px;
  margin-left: 10px;
}

.width-1240 {
  min-width: 1000px;
}

/* system*/
.block-chain-input {
  width: 200px;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-container {
  flex: 1;
  max-width: 300px;
}

.login-form {
  flex: 1;
}

.inline-formitem {
  display: inline-block;
  margin-bottom: 0;
}

.multi-depth-menuitem {
  padding-left: 60px !important;
}

.gray {
  background-color: #c2c2c0;
}

.text-ellipsis {
  max-width: 180px;
  white-space: nowrap;
  /* width: 180px; */
  overflow: hidden;
  text-overflow: ellipsis;
}